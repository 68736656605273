
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rules } from 'async-validator';

import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';

import { useUpdateUser, useUser } from '@/composables/api';
import { PartialUser, UserStatus } from '@/interfaces/User';
import useFormErrors from '@/utils/validate';
import { IntBoolean } from '@/services/api';
import { useI18n } from 'vue-i18n';


const DEFAULT_FORM_VALUES: PartialUser & Partial<{avatarLink: string}> & Partial<{bannerLink: string}>= { //& Partial<{avatarLink: string}>
  avatarLink: '',
  avatar: '',
  bannerLink: '',
  banner: '',
  status: UserStatus.ENABLE
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const userId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();
    const { t } = useI18n();


    const formRef = ref<typeof ElForm>();
    const formValues = ref(DEFAULT_FORM_VALUES);
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateUser();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { data, dataUpdatedAt } = useUser({ userId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const handleCoverCrop = ({ path }: UploadImageEvent) => {
      formValues.value.avatar = path;
    };

    const handleBannerCrop = ({ path }: UploadImageEvent) => {
      formValues.value.banner = path;
    };

    const rules: Rules = {
      username: [
        {
          required: true
        }
      ],
      email: [
        {
          required: true
        }
      ],
      firstName: [
        {
          required: true
        }
      ],
      lastName: [
        {
          required: true
        }
      ]
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { userId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.push({
                      name: 'list-users'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );
            }
          }
        });
    };

    return {
      IntBoolean,
      page,
      formValues,
      formRef,
      rules,
      handleCoverCrop,
      handleBannerCrop,
      UserStatus,
      isUpdatedLoading,
      submitForm,
      formErrors,
      t,
      bindFormItemError
    };
  }
});
